/* eslint-disable no-console */
// https://fluentsite.z22.web.core.windows.net/quick-start
import "./App.css";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "../main/Context";
import config from "../lib/config";
import BizCardList from "../app/BizCardList";
import Nav from "../app/views/Nav";
import List from "../app/List";
import NewContact from "../app/NewContact";
import TopNav from "../app/TopNav";
import MyBizCard from "../app/MyBizCard";
import NewBizCards from "../app/BizCardNew";
import BizCardsearch from "../app/BizCardsearch";
import AuthEnd from "../app/AuthEnd";

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });
  if (loading) { return <Spinner label="Loading..." />; }
  if (!teamsUserCredential) { return <Navigate to="/authend" />; }
  // console.log(teamsUserCredential);
  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme,
                colorNeutralBackground3: "#eeeeee",
              }
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <div className="app">
          <div className="leftnav"><Nav /></div>
          <div className="topnav"><TopNav /></div>
          {/* {license && <></>} */}
          <div className="main">
            <div className="tabContent">
              <Router>
                {loading ? (<Spinner style={{ margin: 100 }} /> ) : (
                  <Routes>
                    <Route path="/bizcards" element={<BizCardList />} />
                    <Route path="/newbizcard" element={<NewBizCards />} />
                    <Route path="/bizcardsearch" element={<BizCardsearch />} />
                    <Route path="/list" element={<List />} />
                    <Route path="/newContact" element={<NewContact />} />
                    <Route path="/mybizcard" element={<MyBizCard />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route path="/authend" element={<AuthEnd />} />
                  </Routes>
                )}
              </Router>
            </div>
          </div>
          <div className="rightnav"></div>
          <div className="footer"></div>
        </div>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}



/*
import React from "react";
import "./App.css";
// import { TabList, Tab, TabValue, SelectTabEvent, SelectTabData, } from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import NewContact from "./app/NewContact";
// import Nav from "./app/cmpt/Nav";
import List from "./app/List";
import BizCards from "./app/BizCards";
import config from "./lib/config";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import MyBizCard from "./app/MyBizCard";

export default function App() {
  // const [selectedValue, setSelectedValue] = React.useState<TabValue>("local");
  const [license, setLicense] = React.useState<any>(null);
  // const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
  //   setSelectedValue(data.value);
  // };
  const { themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId || "",
  });
  React.useEffect(() => {
    if (license) {
      console.log(license);
    } else {
      console.log("no license");
      setLicense(true);
    }
  }, [license]);
  return (
    <TeamsFxContext.Provider value={{ themeString, teamsUserCredential }}>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/bizcards" element={<BizCards />} />
          <Route path="/list" element={<List />} />
          <Route path="/newContact" element={<NewContact />} />
          <Route path="/mybizcard" element={<MyBizCard />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </TeamsFxContext.Provider> 
  );
}

          <div className="app">
            <div className="main">
              <div className="tabContent">
                {selectedValue === "bizcard" && (<div><BizCards /></div>)}
                {selectedValue === "list" && (<div><List /></div>)}
                {selectedValue === "newContact" && (<div><NewContact /></div>)}
                {selectedValue === "mybizcard" && (<div></div>)}
              </div>
            </div>
            <div className="topnav">
              <TabList className="tablist" selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab className="tab" id="tab1" value="bizcard">Biz Cards</Tab>
                <Tab className="tab" id="tab2" value="list">All Contacts</Tab>
                <Tab className="tab" id="tab3" value="newContact">New Contact</Tab>
                <Tab className="tab" id="tab4" value="mybizcard">My Virtual Card</Tab>
              </TabList>
            </div>
          {license && <></>}
          <div className="leftnav"><Nav /></div>
          <div className="rightnav"></div>
          <div className="footer"></div>
        </div>

const url = 'https://cutuluscontacts.azurewebsites.net';
  const [user, setUser] = React.useState<any>(null);
  async function getCurrentUser() {
    try {
        const teamsfx = new TeamsFx();
        const userInfo = await teamsfx.getUserInfo();
        setUser(userInfo);
    } catch (error) {
        console.error("Failed to get user information:", error);
    }
  }
  React.useEffect(() => {
    getCurrentUser();
  }, []);
  React.useEffect(() => {
    if (user) {
      fetch(`${url}/license/${user.objectId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'no-cors'
      }).then(response => {
        if (!response.ok) {
          throw new Error(`Server error: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => setLicense(data))
      .catch(error => {
        const license = {
          usersid : user.objectId,
          email: user.preferredUserName,
          tenantInfo: user.tenantId,
          startDate: new Date(),
          endDate : "2025-09-18T14:07:22.278Z"
        };
        fetch(`${url}/license`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(license),
          mode: 'no-cors'
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Server error: ${response.status} ${response.statusText}`);
            }
            return response.json();
          })
          .then(data => setLicense(data))
          .catch(error => {
            console.error("Failed to fetch:", error);
          });
      });
    }  
  }, [user]);
  React.useEffect(() => {
    if (license) {
      console.log(license);
    }
  }, [license]);

*/