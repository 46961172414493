/* eslint-disable no-console */
const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: "https://login.microsoftonline.com/d7b0ab77-a95f-4ba9-8f84-ba6830536dd4",
  redirectUri: "https://bizcards.azurewebsites.net/authend",
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
  apiNameGetBizCards: process.env.REACT_APP_FUNC_NAME_GET_BIZ_CARDS,
  apiNameGetAddress: process.env.REACT_APP_FUNC_NAME_GET_ADDRESS,
  apiNameGetCompany: process.env.REACT_APP_FUNC_NAME_GET_COMPANY,
  apiNameGetContacts: process.env.REACT_APP_FUNC_NAME_GET_CONTACTS,
  apiNameGetLicense: process.env.REACT_APP_FUNC_NAME_GET_LICENSE,
  apiNameGetPhone: process.env.REACT_APP_FUNC_NAME_GET_PHONE,
  apiNameGetUser: process.env.REACT_APP_FUNC_NAME_GET_USER,
  apiNameCreateBizCards: process.env.REACT_APP_FUNC_NAME_CREATE_BIZ_CARDS,
  apiNameCreateAddress: process.env.REACT_APP_FUNC_NAME_CREATE_ADDRESS,
  apiNameCreateCompany: process.env.REACT_APP_FUNC_NAME_CREATE_COMPANY,
  apiNameCreateContacts: process.env.REACT_APP_FUNC_NAME_CREATE_CONTACTS,
  apiNameCreateLicense: process.env.REACT_APP_FUNC_NAME_CREATE_LICENSE,
  apiNameCreatePhone: process.env.REACT_APP_FUNC_NAME_CREATE_PHONE,
  apiNameCreateUser: process.env.REACT_APP_FUNC_NAME_CREATE_USER,
  apiNameDeleteBizCards: process.env.REACT_APP_FUNC_NAME_DELETE_BIZ_CARDS,
  apiNameDeleteAddress: process.env.REACT_APP_FUNC_NAME_DELETE_ADDRESS,
  apiNameDeleteCompany: process.env.REACT_APP_FUNC_NAME_DELETE_COMPANY,
  apiNameDeleteContacts: process.env.REACT_APP_FUNC_NAME_DELETE_CONTACTS,
  apiNameDeleteLicense: process.env.REACT_APP_FUNC_NAME_DELETE_LICENSE,
  apiNameDeletePhone: process.env.REACT_APP_FUNC_NAME_DELETE_PHONE,
  apiNameDeleteUser: process.env.REACT_APP_FUNC_NAME_DELETE_USER,
  apiNameUpdateBizCards: process.env.REACT_APP_FUNC_NAME_UPDATE_BIZ_CARDS,
  apiNameUpdateAddress: process.env.REACT_APP_FUNC_NAME_UPDATE_ADDRESS,
  apiNameUpdateCompany: process.env.REACT_APP_FUNC_NAME_UPDATE_COMPANY,
  apiNameUpdateContacts: process.env.REACT_APP_FUNC_NAME_UPDATE_CONTACTS,
  apiNameUpdateLicense: process.env.REACT_APP_FUNC_NAME_UPDATE_LICENSE,
  apiNameUpdatePhone: process.env.REACT_APP_FUNC_NAME_UPDATE_PHONE,
  apiNameUpdateUser: process.env.REACT_APP_FUNC_NAME_UPDATE_USER,
  apiNameGetAllBizCards: process.env.REACT_APP_FUNC_NAME_GET_ALL_BIZ_CARDS,
  apiNameGetAllAddress: process.env.REACT_APP_FUNC_NAME_GET_ALL_ADDRESS,
  apiNameGetAllCompany: process.env.REACT_APP_FUNC_NAME_GET_ALL_COMPANY,
  apiNameGetAllContacts: process.env.REACT_APP_FUNC_NAME_GET_ALL_CONTACTS,
  apiNameGetAllLicense: process.env.REACT_APP_FUNC_NAME_GET_ALL_LICENSE,
  apiNameGetAllPhone: process.env.REACT_APP_FUNC_NAME_GET_ALL_PHONE,
  apiNameGetAllUser: process.env.REACT_APP_FUNC_NAME_GET_ALL_USER,
  apiNameUploadFile: process.env.REACT_APP_FUNC_NAME_UPLOADFILE
};

export default config;
