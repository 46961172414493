import React from "react";
// import * as axios from "axios";

export default function BizCardsearch() {
  const [search, setSearch] = React.useState("");

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   axios.get(`https://graph.microsoft.com/v1.0/users?$filter=displayName eq '${search}'`)
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div>
      <form onSubmit={() =>{}}>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search for a user"
        />
        <button type="submit">Search</button>
      </form>
    </div>
  );
}
