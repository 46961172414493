

import React from 'react';
import { BizCard } from '../types/types';
import { Input, makeStyles, SelectTabData, SelectTabEvent, Tab, TabList } from '@fluentui/react-components';
import { BookGlobe24Regular, Dismiss20Filled } from '@fluentui/react-icons';

interface Props {
  card: BizCard;
  editable?: boolean;
}

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "50px 20px",
    rowGap: "20px",
  },
  panels: {
    padding: "0 10px",
    "& th": {
      textAlign: "left",
      padding: "0 30px 0 0",
    },
  },
  propsTable: {
    "& td:first-child": {
      // fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      padding: "0 30px 0 0",
    },
  },
});

const BizCardDetail: React.FC<Props> = (props) => {
  const { card, editable } = props;

  const styles = useStyles();
  const [selectedValue, setSelectedValue] = React.useState<string>("contact");
  const [lightbox, setLightbox] = React.useState<HTMLDivElement | null>(null);
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value as string);
  };
  
  const containerStyle: React.CSSProperties = {
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    gap: '36px',
    marginBottom: '24px',
    height: '87%',
    flexGrow: 1,
    overflow: 'hidden',
  };

  const imageContainerStyle: React.CSSProperties = {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    padding: '16px'
  };

  const imageStyle: React.CSSProperties = {
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    margin: '10px 0',
    objectFit: 'cover',
  };

  const verticalDimentions = {
    height: '400px',
    maxWidth: '100%'
  }

  const horizontalDimentions = {
    height: '200px',
    maxHeight: '200px',
    width: '100%'
  };

  const getImageStyle = (imagePath?: string) => {
    const img = new Image();
    img.src = imagePath || '';
    if (img.width > img.height) {
      return { ...imageStyle, ...horizontalDimentions };
    }
    return { ...imageStyle, ...verticalDimentions };
  };

  const infoContainerStyle: React.CSSProperties = {
    backgroundColor: '#f9f9f9',
    padding: '24px',
    borderRadius: '8px',
    overflowY: 'hidden',
    flex: '2',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  };
  // const labelStyle: React.CSSProperties = {
  //   fontSize: '16px',
  //   color: '#555',
  // };
  const base64ToBlobUrl = (base64: string, contentType: string = 'image/png') => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return URL.createObjectURL(blob);
  };

  const openLightbox = (imageSrc: string) => {
    if (lightbox) {
      lightbox.style.display = 'block';
      lightbox.querySelector('img')!.src = imageSrc;
    }
  };

  const closeLightbox = () => {
    if (lightbox) {
      lightbox.style.display = 'none';
    }
  };
  
  const Contact = React.memo((contact: BizCard) => {
    return (
      <div>
        <h3>Contact Information</h3>
        <table className={styles.propsTable}>
          <tbody>
            <tr>
              <th>Phone:</th>
              <td>
                <Input 
                  appearance='underline'
                  defaultValue={contact.phone} 
                  onChange={(e) => console.log(e.target.value)} 
                  disabled={!editable}
                />
              </td>
            </tr>
            <tr>
              <th>Email:</th>
              <td>
                <Input 
                  appearance='underline' 
                  defaultValue={contact.email} 
                  onChange={(e) => console.log(e.target.value)} 
                  disabled={!editable}
                />
              </td>
            </tr>
            <tr>
              <th>Address:</th>
              <td>
                <Input 
                  appearance='underline' 
                  defaultValue={contact.address} 
                  onChange={(e) => console.log(e.target.value)} 
                  disabled={!editable}
                />
              </td>
            </tr>
            <tr>
              <th>Website:</th>
              <td>
                <Input 
                  appearance='underline' 
                  defaultValue={contact.website} 
                  onChange={(e) => console.log(e.target.value)} 
                  disabled={!editable}
                />
                <a href={contact.website} target="_blank" rel="noopener noreferrer">
                  <BookGlobe24Regular />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>  );
  });
  const Company = React.memo((contact: BizCard) => {
    return (
      <div>
        <h3>Company Information</h3>
        company?: string;
    jobtitle?: string;
    department?: string;

      </div>
    );
  });
  const Notes = React.memo((contact: BizCard) => {
    return (
      <div>
      <h3>Notes</h3>
      <p>{card.notes}</p>
      {card.location}
    </div>
);
  });
  return (
    <div style={containerStyle} key={card.id}>
      {/* Lightbox */}
      <div
        ref={setLightbox}
        style={{
          display: 'none',
          position: 'fixed',
          zIndex: 1000,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'clip',
          backgroundColor: 'rgba(0,0,0,0.9)',
          objectFit: 'cover',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
        onClick={closeLightbox}
      >
        <Dismiss20Filled
          style={{
            position: 'absolute',
            top: '20px',
            right: '35px',
            color: '#fff',
            fontSize: '40px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
          onClick={closeLightbox}
        />
        <img
          style={{
            maxWidth: '80%',
            maxHeight: '80%',
            objectFit: 'cover',
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        />
      </div>
      <div style={imageContainerStyle}>
        <img src={base64ToBlobUrl(card.frontImagePath!)}
          alt={`${card.name}'s business card front`} 
          style={getImageStyle(card.frontImagePath)}
          onClick={() => openLightbox(card.frontImagePath!)}
        />
        <img src={base64ToBlobUrl(card.backImagePath!)}
          alt={`${card.name}'s business card back`} 
          style={getImageStyle(card.backImagePath)}
          onClick={() => openLightbox(card.backImagePath!)}
        />
      </div>
      <div style={infoContainerStyle}>
        <h2>{card.name}</h2>
        <TabList onTabSelect={onTabSelect} selectedValue={selectedValue} size="large" >
          <Tab className='contact' id='contact' value="contact" >Contact</Tab>
          <Tab className='address' id='address' value='address' >Addresses</Tab>
          <Tab className='phone' id='phone' value='phone' >Phones</Tab>
          <Tab className='company' id='company' value='company' >Company</Tab>
          <Tab className='notes' id='notes' value='notes' >Notes</Tab>
        </TabList>
        <div className={styles.panels}>
          {selectedValue === 'contact' && <Contact {...card} />}
          {selectedValue === 'company' && <Company {...card} />}
          {selectedValue === 'notes' && <Notes {...card} />}
        </div>
        {/* <div>
          <h3>Contact Information</h3>
          <p style={labelStyle}><strong>Phone:</strong><Input appearance='underline' defaultValue={card.phone} onChange={(e) => console.log(e.target.value)} /></p>
          <p style={labelStyle}><strong>Email:</strong><Input appearance='underline' defaultValue={card.phone} onChange={(e) => console.log(e.target.value)} /> {card.email}</p>
          <p style={labelStyle}><strong>Address:</strong><Input appearance='underline' defaultValue={card.phone} onChange={(e) => console.log(e.target.value)} /> {card.address}</p>
          <p style={labelStyle}><strong>Website:</strong><Input appearance='underline' defaultValue={card.phone} onChange={(e) => console.log(e.target.value)} /> <a href={card.website} target="_blank" rel="noopener noreferrer">{card.website}</a></p>
        </div> */}
        {/* <div>
          <h3>Professional Details</h3>
          <p style={labelStyle}><strong>Company:</strong> {card.company}</p>
          <p style={labelStyle}><strong>Job Title:</strong> {card.jobtitle}</p>
          <p style={labelStyle}><strong>Department:</strong> {card.department}</p>
          <p style={labelStyle}><strong>Location:</strong> {card.location}</p>
        </div> */}
      </div>
    </div>

  );
};

export default BizCardDetail;
