/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Spinner } from "@fluentui/react-components";
import { CardUiRegular } from "@fluentui/react-icons";
// import getBizCards from "./components/GetBizCards";
import BizCardDetail from "./components/BizCardDetail";
import { BizCard } from "./types/types";
import NewBizCards from "./BizCardNew";
import * as axios from "axios";
import { BearerTokenAuthProvider, createApiClient, TeamsUserCredential } from '@microsoft/teamsfx';
import config from '../lib/config';
import { TeamsFxContext } from "../main/Context";
import { useData } from "@microsoft/teamsfx-react";

async function getBizCards(teamsUserCredential: TeamsUserCredential) {
  try {
    const apiBaseUrl = config.apiEndpoint + "/api/";
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      apiBaseUrl,
      new BearerTokenAuthProvider(async () => (await teamsUserCredential.getToken(""))!.token)
    );
    console.log(apiClient);
    const response = await apiClient.get('getBizCards');
    console.log(response.data);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the deployment of Azure Functions App, please deploy Azure Functions (Run command palette "Teams: Deploy") first before running this App`;
      } else if (err.message === "Network Error") {
        funcErrorMsg =
          "Cannot call Azure Functions due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          funcErrorMsg += `make sure to start Azure Functions locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Functions (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
        }
      } else {
        funcErrorMsg = (err as Error).message;
        if ((err as axios.AxiosError).response?.data?.error) {
          funcErrorMsg += ": " + (err.response?.data?.error || "Unknown error");
        }
      }
      throw new Error(funcErrorMsg);
    }
    throw err;
  }
}

export default function BizCardList() {
  const [needConsent, setNeedConsent] = React.useState(false);
  const teamsUserCredential = React.useContext(TeamsFxContext).teamsUserCredential;
  const { loading, data, error } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential!.login(["User.Read"]);
      setNeedConsent(false);
    }
    try {
      const functionRes = await getBizCards(teamsUserCredential);
      console.log(functionRes);
      setBizCards(functionRes);
      return functionRes;
    } catch (error: any) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      }
    }
  });
  // React.useEffect(() => {
  //   const fetchBizCards = async (teamsUserCredential: TeamsUserCredential) => {
  //     try {
  //       const apiBaseUrl = config.apiEndpoint + "/api/";
  //       const apiClient = createApiClient(
  //         apiBaseUrl,
  //         new BearerTokenAuthProvider(async () => (await teamsUserCredential.getToken(""))!.token)
  //       );
  //       const response = await apiClient.get('getBizCards');
  //       console.log('Server response:', JSON.stringify(response.data, null, 2));
  //       setBizCards(response.data);
  //       return response.data;
  //     } catch (error) {
  //       console.error('Error fetching biz cards:', error);
  //       // setError(error instanceof Error ? error.message : String(error));
  //     }
  //   };
  //   fetchBizCards();
  // }, []);
  const [bizCards, setBizCards] = React.useState<BizCard[]>([]);
  const [selectedCard, setSelectedCard] = React.useState<BizCard | null>(null);
  const [editBizCard, setEditBizCard] = React.useState(false);
  const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '3vh',
    right: '2vw',
    zIndex: 1000,
  };
  const editButtonStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '3vh',
    right: '9vw',
    zIndex: 1000,
  };
  const cardStyle: React.CSSProperties = {
    border: '1px solid #ccc',
    padding: '12px',
    cursor: 'pointer',
    textAlign: 'center',
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: '200px',
    width: '280px',
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };
  const imageThumbnailStyle: React.CSSProperties = {
    display: 'block',
    border: '1px solid #ccc',
    borderRadius: '8px',
    width: '100%',
    height: '145px',
    margin: '0',
    objectFit: 'cover'
  }
  const titleStyle: React.CSSProperties = {
    marginBottom: '8px',
    marginTop: '0px',
    padding: '0px'
  }
  const openBizCard = (card: BizCard) => { setSelectedCard(card); };
  const closeBizCard = () => { setSelectedCard(null); };
  const toggleEditBizCard = () => {
    setEditBizCard(prev => !prev);
    // TODO: get the edited card data and send it to the server
  }

  // async function fetchBizCards() {
  // //   if (bizCards.length === 0 || bizCards) {
  // //     fetch('https://cutuluscontacts.azurewebsites.net/bizcards', {
  // //       method: 'GET',
  // //       headers: {
  // //         'Content-Type': 'application/json',
  // //       },
  // //     }).then((res) => {res.json().then((data) => {
  // //       console.log('Server response:', data);
  // //       if (data.length !== 0) {
  // //         console.log('Server response:', data);
  // //         setBizCards(data);
  // //         return data
  // //       }
  // //       return data;
  // //     })}).catch((error) => {
  // //       console.error('Error uploading biz card data:', error);
  // //     });
  // //   }
    
  //   try {
  //     const response = await getBizCards();
  //     console.log('Server response:', JSON.stringify(response, null, 2));
  //     if (response.length !== 0) {
  //         return response
  //     }
  //     return response;
  //   } catch (error) {
  //     console.error('Error uploading biz card data:', error);
  //   }
  // }
  // Fetch business cards when the component mounts

  // Log when selectedCard changes
  React.useEffect(() => {
    if (selectedCard) {
      console.log(`Selected card ID: ${selectedCard}`);
    }
  }, [selectedCard]);
  // disable scrolling when a card is selected
  React.useEffect(() => {
    if (selectedCard) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    // Cleanup on unmount
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [selectedCard]);
  const base64ToBlobUrl = (base64: string, contentType: string = 'image/png') => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return URL.createObjectURL(blob);
  };
  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <Button><CardUiRegular /> New Biz Card</Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Upload New Biz Card</DialogTitle>
            <DialogContent>
              <NewBizCards />
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <br />
      {!data && <>Data<Spinner /></>}
      {!!error && <>Err...</>}
      {loading && <>Load<Spinner /></>}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', gap: '6px', margin: '8px'}}>
        {!loading && !!data && !error && <pre>{JSON.stringify(data, null, 2)}</pre>}
        {/* {bizCards.map((card, index) => (
          <div
            key={index}
            style={cardStyle}
            onClick={() => openBizCard(card)}
          >
            <p style={titleStyle}>{card.name}</p>
            <img
              src={base64ToBlobUrl(card.frontImagePath!)}
              alt={`${card.name}'s business card`}
              style={imageThumbnailStyle}
            />
          </div>
        ))} */}
      </div>
      {/* Modal */}
      {selectedCard && (
        <Dialog open={true} onOpenChange={closeBizCard}>
          <DialogSurface style={{ maxWidth: '75vw', maxHeight: '90vh', overflow: 'hidden' }}>
            <DialogBody>
              <DialogContent style={{ height: '90vh' }}>
                <BizCardDetail card={selectedCard} key={selectedCard.id} editable={editBizCard} />
              </DialogContent>
              <DialogActions>
                <Button style={editButtonStyle} appearance="primary" onClick={() => toggleEditBizCard()}>
                  {editBizCard ? 'Save' : 'Edit'}
                </Button>
                <Button style={closeButtonStyle} appearance="primary" onClick={closeBizCard}>Close</Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </div>
  );
}