import { TabList, Tab } from "@fluentui/react-components"; //, TabValue, SelectTabEvent, SelectTabData,

export default function TopNav() {
  return (
    <div className="topnav">
      <TabList className="tablist">
        <Tab className="tab" id="tab1" value="bizcard" onClick={() => window.location.href = "#/bizcards"}>Biz Cards</Tab>
        <Tab className="tab" id="tab2" value="list"onClick={() => window.location.href = "#/list"}>All Contacts</Tab>
        <Tab className="tab" id="tab3" value="newContact"onClick={() => window.location.href = "#/newContact"}>New Contact</Tab>
        <Tab className="tab" id="tab4" value="mybizcard"onClick={() => window.location.href = "#/mybizcard"}>My Virtual Card</Tab>
      </TabList>
    </div>
  );
};