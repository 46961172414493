import React from "react";
import { AgGridReact } from "ag-grid-react";
import "../main/aggrid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export default function List() {
  const [rowData, setRowData] = React.useState([
    {name: "John", email: "john@gmail.com"},
    {name: "Jane", email: "jane@gmail.com"},
  ]);
  const [colDefs, setColDefs] = React.useState<any[]>([
    {field: "name"},
    {field: "email"},
  ]);

  React.useEffect(() => {
    if (rowData.length === 0) {
      setColDefs([
      ]);
      setRowData([
      ]);
    }
  }, [rowData]);
  return (
    <div className="ag-theme-alpine" style={{ height: 800, width: 400 }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
      />
    </div>
  );
}