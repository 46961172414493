import { useState, useEffect } from 'react';
import { BizCard } from './types/types';
import { Input } from '@fluentui/react-components';

export default function MyBizCards() {
  const [bizCards, setBizCards] = useState<BizCard[]>([]);
  const [newBizCard, setNewBizCard] = useState<BizCard>({
    id: '', // Add the id property here
    name: '',
    email: '',
    phone: '',
    address: '',
    website: '',
    createdAt: new Date(),
    userId: "0",
    userName: '',
    tenantId: "0",
  });
  useEffect(() => {
    const fetchBizCards = async () => {
      try {
        // Replace with your API endpoint or static data
        const response = await fetch('/api/bizcards');
        const data = await response.json();
        setBizCards(data);
      } catch (err) {
        console.error('Failed to fetch biz cards:', err);
      }
    };

    fetchBizCards();
  }, []);

  function handleAddBizCard(event: React.MouseEvent<HTMLButtonElement>): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
      <h3>My Virtual Cards</h3>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', textAlign: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
          {bizCards.map(bizCard => (
            <div key={bizCard.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
              <div>Name: {bizCard.name}</div>
              <div>Email: {bizCard.email}</div>
              <div>Phone: {bizCard.phone}</div>
              <div>Address: {bizCard.address}</div>
              <div>Website: {bizCard.website}</div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
          <h3>Add New Virtual Card</h3>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
            <Input appearance='underline'
              type="text"
              name="name"
              placeholder="Name"
              value={newBizCard.name}
              onChange={(e) => setNewBizCard({ ...newBizCard, name: e.target.value })}
            />
            <Input appearance='underline'
              type="text"
              name="email"
              placeholder="Email"
              value={newBizCard.email}
              onChange={(e) => setNewBizCard({ ...newBizCard, email: e.target.value })}
            />
            <Input appearance='underline'
              type="text"
              name="phone"
              placeholder="Phone"
              value={newBizCard.phone}
              onChange={(e) => setNewBizCard({ ...newBizCard, phone: e.target.value })}
            />
            <Input appearance='underline'
              type="text"
              name="address"
              placeholder="Address"
              value={newBizCard.address}
              onChange={(e) => setNewBizCard({ ...newBizCard, address: e.target.value })}
            />
            <Input appearance='underline'
              type="text"
              name="website"
              placeholder="Website"
              value={newBizCard.website}
              onChange={(e) => setNewBizCard({ ...newBizCard, website: e.target.value })}
            />
            <button onClick={handleAddBizCard}>Add Virtual Card</button>
          </div>
      </div></div>
    </div>
  );
}