// import { Input } from "@fluentui/react-components";
import React from "react";
import ContactNew from "./components/ContactNew";
import PhoneNew from "./components/PhoneNew";
import AddressNew from "./components/AddressNew";
import CompanyNew from "./components/CompanyNew";
import BizCardNew from "./BizCardNew";
import { SelectTabData, SelectTabEvent, Tab, TabList } from "@fluentui/react-components";
import NoteDetails from "./components/NoteDetails";

export default function NewContact() {
  const [selectedValue, setSelectedValue] = React.useState<string>("contact");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => { setSelectedValue(data.value as string); };
  // const [company, setCompany] = React.useState("");
  // const [fname, setFname] = React.useState("");
  // const [lname, setLname] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [phone, setPhone] = React.useState("");
  // const [address, setAddress] = React.useState("");
  // const [city, setCity] = React.useState("");
  // const [state, setState] = React.useState("");
  // const [zip, setZip] = React.useState("");
  // const [country, setCountry] = React.useState("");
  // const [businessHrs, setBusinessHrs] = React.useState("");
  // const [businessField, setBusinessField] = React.useState("");
  // const [specNeeds, setSpecNeeds] = React.useState("");
  // const [reqdServ, setReqdServ] = React.useState("");
  // const [hasMSP, {setTrue, setFalse}] = React.useState(false);
  // const [currServProv, setCurrServProv] = React.useState("");
  // const [nodes, setNodes] = React.useState<any[]>([
  //   {itm: 0, name: "PC", qty: 0, price: 100},
  //   {itm: 0, name: "Servers", qty: 0, price: 100},
  //   {itm: 0, name: "Phones", qty: 0, price: 100},
  //   {itm: 0, name: "Cameras", qty: 0, price: 100},
  //   {itm: 0, name: "PC", qty: 0, price: 100}
  // ]);
  // const [nodeInfo, setNodeInfo] = React.useState<any[]>([]);
  // const [networkSize, setNetworkSize] = React.useState(0);
  // const [needNwEq, setNeedNwEq] = React.useState("");
  // const [nwEq, setNwEq] = React.useState("");
  // const [existingNw, setExistingNw] = React.useState("");
  // const [currNtwk, setCurrNtwk] = React.useState("");
  // const [currITdept, setCurrITdept] = React.useState("");
  // const [msp, setMSP] = React.useState({company: "", contact: "", phone: "", email: ""});
  // const [wiring, setWiring] = React.useState(""); //Cat 5 / Cat 5e / Cat 6 / Unknown
  // const [timeFrame, setTimeFrame] = React.useState(""); //1-3 months / 3-6 months / 6-12 months / 12+ months
  // const [budget, setBudget] = React.useState(""); //Under $5,000 / $5,000 - $10,000 / $10,000 - $20,000 / $20,000 - $50,000 / $50,000 - $100,000 / $100,000 - $250,000 / $250,000 - $500,000 / $500,000 - $1,000,000 / Over $1,000,000
  // const [imageUrl, setImageUrl] = React.useState<string | null>(null);
  // const fileInputRef = React.useRef<HTMLInputElement>(null);

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const url = URL.createObjectURL(file);
  //     setImageUrl(url);
  //   }
  // };

  // const handleButtonClick = () => {
  //   fileInputRef.current?.click();
  // };
  return (
    <div>
      <div>
        <TabList onTabSelect={onTabSelect} selectedValue={selectedValue} size="large">
          <Tab className='contact' id='contact' value="contact" >Contact</Tab>
          <Tab className='phone' id='phone' value='phone' >Phones</Tab>
          <Tab className='address' id='address' value='address' >Addresses</Tab>
          <Tab className='company' id='company' value='company' >Company</Tab>
          <Tab className='bizcard' id='bizcard' value='bizcard' >BizCard</Tab>
          <Tab className='notes' id='notes' value='notes' >Notes</Tab>
        </TabList>
        {selectedValue === 'contact' && <ContactNew />}
        {selectedValue === 'address' && <AddressNew />}
        {selectedValue === 'phone' && <PhoneNew contactId="1" />}
        {selectedValue === 'company' && <CompanyNew />}
        {selectedValue === 'bizcard' && <BizCardNew />}
        {selectedValue === 'notes' && <NoteDetails/>}
       </div>
    </div>          
  );
}