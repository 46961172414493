import React from "react";
import * as axios from "axios";
import { Button } from "@fluentui/react-components";
import BizCard from "./components/BizCard";
import {newBizCard, uploadFile} from "./components/UploadBizCard";
import { TeamsFxContext } from "../main/Context";
import { useData } from "@microsoft/teamsfx-react";
import { ContactCard48Regular, ContactCard48Filled } from '@fluentui/react-icons';
import { Spinner } from "@fluentui/react-components";
import { TeamsUserCredential } from "@microsoft/teamsfx";

export default function NewBizCards() {
  const { teamsUserCredential } = React.useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const [frontImg, setFrontImage] = React.useState<File | null>(null); 
  const [backImg, setBackImage] = React.useState<File | null>(null); 
  const frontFileInputRef = React.useRef<HTMLInputElement>(null);
  const backFileInputRef = React.useRef<HTMLInputElement>(null);
  interface UploadResponse { message: string; }
  const [uploadResponse, setUploadResponse] = React.useState<UploadResponse | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, setImage: React.Dispatch<React.SetStateAction<File | null>>) => {
    const file = e.target.files?.[0] || null;
    setImage(file);
    setUploadResponse({ message: "image set" });
  };
  const handleButtonClick = () => {
    frontFileInputRef.current?.click();
    setUploadResponse({ message: "Uploading Front BizCard" });
  };
  const handleBackButtonClick = () => {
    backFileInputRef.current?.click();
    setUploadResponse({ message: "Uploading Back BizCard" });
  };
  const userName = loading || error ? "" : data!.preferredUserName.toLowerCase();
  const handleUpload = async () => {
    if(teamsUserCredential) {
      const functionRes = await callFunction(teamsUserCredential);
      return functionRes;
    }
  };
  const handler = async () => {
    console.log('called');
    if(frontImg) {
      try {
        console.log('upload called');
        const upld = await uploadFile(frontImg);
        console.log(upld);
        setUploadResponse(upld);
      } catch (error) {
        setUploadResponse({ message: String(error) });
      }
    }
  };
  function upload(file: File): Promise<string> {
    return new Promise((res, rej) => {
      const upld = uploadFile(file);
      res(upld);
    })
  }
  async function callFunction(teamsUserCredential: TeamsUserCredential) {
    setUploadResponse({ message: "    Uploading BizCard" });
    if (frontImg || backImg) {
      const frntBase64 = frontImg ? await upload(frontImg) : "";
      const backBase64 = backImg ? await upload(backImg) : "";
      const bizCardObj = {
        frontImagePath: frntBase64,
        backImagePath: backBase64,
        createdAt: new Date().toISOString(),
        userId: data!.objectId,
        tenantId: data!.tenantId,
        userName: userName,
      };
      setUploadResponse({ message: "bizCard object created" });
      try {
        const tokenResponse = await teamsUserCredential.getToken("");
        const token = tokenResponse?.token;
        setUploadResponse({ message: "Token obtained" });
        // console.log('Token:', token);
        if (token) {
          setUploadResponse({ message: "Calling Azure Functions" });
          const funcres = await newBizCard(bizCardObj, token);
          console.log('Function response:', funcres);
          setUploadResponse({ message: "BizCard uploaded successfully" });
        } else {
          setUploadResponse({ message: "Failed to upload BizCard" });
          setTimeout(() => {
            setUploadResponse(null);
          }, 5000);
          throw new Error("Failed to retrieve access token.");
        }
      } catch (err: unknown) {
        setUploadResponse({ message: `${err}` });
        if (axios.default.isAxiosError(err)) {
          let funcErrorMsg = "";
          if (err?.response?.status === 404) {
            setUploadResponse({ message: "Azure Functions App not deployed" });
            funcErrorMsg = `There may be a problem with the deployment of Azure Functions App, please deploy Azure Functions (Run command palette "Teams: Deploy") first before running this App`;
          } else if (err.message === "Network Error") {
            setUploadResponse({ message: "Network Error" });
            funcErrorMsg =
              "Cannot call Azure Functions due to network error, please check your network connection status and ";
            if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
              funcErrorMsg += `make sure to start Azure Functions locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
            } else {
              setUploadResponse({ message: "App not deployed" });
              funcErrorMsg += `make sure to provision and deploy Azure Functions (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
            }
          } else {
            funcErrorMsg = err.message;
            setUploadResponse({ message: funcErrorMsg });
            if (err.response?.data?.error) {
              funcErrorMsg += ": " + err.response.data.error;
              setUploadResponse({ message: funcErrorMsg });
            }
          }
          throw new Error(funcErrorMsg);
        }
        throw err;
      }
    }
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <Button appearance="primary" onClick={handleButtonClick} style={{ width: '200px', marginBottom: '10px' }}>
            Front of Card
          </Button>
          <input
            type="file"
            ref={frontFileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleImageChange(e, setFrontImage)}
            capture="environment"
          />
          {frontImg === null && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px' }}>
              <ContactCard48Regular />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {frontImg && <BizCard imageUrl={frontImg} />}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <Button appearance="primary" onClick={handleBackButtonClick} style={{ width: '200px', marginBottom: '10px' }}>
            Back of Card
          </Button>
          <input
            type="file"
            ref={backFileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleImageChange(e, setBackImage)}
            capture="environment"
          />
          {backImg === null && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px' }}>
              <ContactCard48Filled />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {backImg && <BizCard imageUrl={backImg} />}
          </div>
        </div>
      </div>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          // disabled={(uploadResponse !== null)}
          appearance="primary" onClick={handleUpload} style={{ maxWidth: '200px' }}>Save</Button>
        <Button
          // disabled={(uploadResponse !== null)}
          appearance="primary" onClick={handler} style={{ maxWidth: '200px' }}>FileUpload</Button>
        <Button appearance="secondary" onClick={() => {
          setFrontImage(null);
          setBackImage(null);
          setUploadResponse(null);
        }} style={{ maxWidth: '200px' }}>New</Button>
      </div>
      {uploadResponse && <div style={{display: 'flex', flexDirection: "row"}} >
        {uploadResponse.message === "Uploading BizCard" && <Spinner />}{uploadResponse.message}</div>}
    </div>
  );
}

  // function fileToBase64(file: File): Promise<string> {
  //   setUploadResponse({ message: "Converting file to base64" });
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = reader.result?.toString().split(',')[1]; // Remove data: URL scheme and only get base64
  //       resolve(base64String || '');
  //       setUploadResponse({ message: "File converted to base64" });
  //     };
  //     reader.onerror = (error) => {
  //       setUploadResponse({ message: "Error converting file to base64" });
  //       reject(error);
  //     };
  //     reader.readAsDataURL(file); // This reads the file as a base64 encoded URL
  //     setUploadResponse({ message: "Reading file as base64" });
  //   });
  // }
