import React from 'react';

interface BizCardProps {
  imageUrl: File;
}

const BizCard: React.FC<BizCardProps> = ({ imageUrl }) => {
  return (
    <div style={{ width: '100%', height: '100%', maxWidth: '400px', maxHeight: '600px', overflow: 'hidden' }}>
      <img
        src={URL.createObjectURL(imageUrl)}
        alt="Uploaded"
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </div>
  );
};

export default BizCard;