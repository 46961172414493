
import React from "react";
import { createPresenceComponent, DrawerBody, DrawerHeader, DrawerHeaderTitle, makeStyles, Button, OverlayDrawer, Toolbar, ToolbarGroup, DrawerHeaderNavigation, ToolbarButton, motionTokens, DrawerFooter, Tooltip } from "@fluentui/react-components";// mergeClasses, InlineDrawer,
import { Dismiss24Regular, GridDotsFilled, ArrowLeft24Regular, ArrowClockwise24Regular, Settings24Regular } from "@fluentui/react-icons";
import { CardUiRegular, CalendarAddRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#e8e8e8",
    justifyContent: "left",
    overflow: "hidden",
  },
  drawer: {
    transitionProperty: "width",
    transitionDuration: "16.666ms", // 60fps
    willChange: "width",
  },
  container: {
    backgroundColor: "#fff",
    height: "100%",
    width: "100%",
  },
  body: {
    flex: "1",
    width: "100%",
    maxWidth: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "right",
  },
  content: {
    display: "flex",
    justifyContent: "center !important",
  },
  level: {
    position: "absolute",
    top: "75px",
    left: 0,
    right: 0,
    bottom: 0,
    ":first-child": { paddingTop: 0, },
    ":last-child": { paddingBottom: 0, },
  },
  icona: { margin: "2px", display: "flex", justifyContent: "left !important", },
  icon24: { fontSize: "24px", margin: "10px" },
  footer: { display: "flex", justifyContent: "space-between", backgroundColor: "#e8e8e8"},
});
const BodyPresenceMotion = createPresenceComponent<{ level: 1 | 2 }>(
  ({ level }) => {
    const keyframes = [
      {
        opacity: 0,
        transform: level === 1 ? "translateX(-100%)" : "translateX(100%)",
      },
      { opacity: 1, transform: "translateX(0)" },
    ];

    const duration = motionTokens.durationNormal;
    const easing = motionTokens.curveEasyEase;

    return {
      enter: {
        keyframes: keyframes,
        duration,
        easing,
      },
      exit: {
        keyframes: [...keyframes].reverse(),
        duration,
        easing,
      },
    };
  }
);
const IconPresenceMotion = createPresenceComponent(() => {
  const keyframes = [
    { opacity: 0, transform: "scale(0)" },
    { opacity: 1, transform: "scale(1)" },
  ];
  return {
    enter: {
      keyframes: keyframes,
      duration: motionTokens.durationNormal,
      easing: motionTokens.curveEasyEase,
    },
    exit: {
      keyframes: [...keyframes].reverse(),
      duration: motionTokens.durationNormal,
      easing: motionTokens.curveEasyEase,
    },
  };
});
export default function Nav() {
  const styles = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [level, setLevel] = React.useState<1 | 2>(1);
  return (
    <div className={styles.root}>
      <OverlayDrawer
        position="start"
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <div className={styles.container}>
          <DrawerHeader>
            <DrawerHeaderNavigation className={styles.header}>
              {/* <Button
                aria-label="Back"
                appearance="subtle"
                icon={<ArrowLeft24Regular />}
              /> */}
              <Toolbar>
                <ToolbarGroup>
                  <IconPresenceMotion visible={level === 2} unmountOnExit>
                    <ToolbarButton
                      aria-label="Back"
                      appearance="subtle"
                      icon={<ArrowLeft24Regular />}
                      onClick={() => setLevel(1)}
                    />
                  </IconPresenceMotion>
                </ToolbarGroup>
                <ToolbarGroup>
                  <IconPresenceMotion visible={level === 1} unmountOnExit>
                    <ToolbarButton aria-label="Reload content" appearance="subtle" icon={<ArrowClockwise24Regular />} onClick={() => window.location.href = "#/tab"} />
                  </IconPresenceMotion>
                  <ToolbarButton aria-label="Settings" appearance="subtle" icon={<Settings24Regular />} onClick={() => window.location.href = "#/settings"} />
                  <ToolbarButton aria-label="Close panel" appearance="subtle" icon={<Dismiss24Regular />} onClick={() => setIsOpen(false)} />
                </ToolbarGroup>
              </Toolbar>
            </DrawerHeaderNavigation>
            <DrawerHeaderTitle>SMB Apps</DrawerHeaderTitle>
          </DrawerHeader>
        </div>
        <div className={styles.body}>
          <BodyPresenceMotion level={1} visible={level === 1} unmountOnExit>
            <DrawerBody className={styles.level}><br />
              <DrawerHeaderTitle>Biz Cards</DrawerHeaderTitle>
              <p><a href="#/bizcards" onClick={() => setIsOpen(false)} >All Cards</a></p>
              <p><a href="#/newbizcard" onClick={() => setIsOpen(false)} >New Card</a></p>
              <p><a href="#/bizcardsearch" onClick={() => setIsOpen(false)} >Search Cards</a></p>
              <br />
              <DrawerHeaderTitle>Contacts</DrawerHeaderTitle>
              <p><a href="#/tab" onClick={() => setIsOpen(false)} >All Contacts</a></p>
              <p><a href="#/newContact" onClick={() => setIsOpen(false)} >New Contact</a></p>
              <p><a href="#/list" onClick={() => setIsOpen(false)} >Search Contacts</a></p>
              <br />
              <DrawerHeaderTitle>Map</DrawerHeaderTitle>
              <p><a href="#/tab" onClick={() => setIsOpen(false)} >Map Cards</a></p>
              <p><a href="#/newContact" onClick={() => setIsOpen(false)} >Map Contacts</a></p>
              <p><a href="#/list" onClick={() => setIsOpen(false)} >Search Contacts</a></p>
            </DrawerBody>
          </BodyPresenceMotion>
          <BodyPresenceMotion level={2} visible={level === 2} unmountOnExit>
            <DrawerBody className={styles.level}><br />
              <DrawerHeaderTitle>Level 2 title</DrawerHeaderTitle>
              <p>Level 2 content</p>
            </DrawerBody>
          </BodyPresenceMotion>
        </div>
        <DrawerFooter className={styles.footer}>
          <Button appearance="subtle" disabled={level === 1} onClick={() => setLevel(1)} >
            Previous
          </Button>
          <Button appearance="primary" disabled={level === 2} onClick={() => setLevel(2)} >
            Next
          </Button>
        </DrawerFooter>
      </OverlayDrawer>
      <div>
        {!isOpen && <div className={styles.content}>
          <Button icon={<GridDotsFilled />} size="medium" appearance="primary"
            className={styles.icona} onClick={() => setIsOpen(!isOpen)} />
          </div>}
        <hr/>
        <div className={styles.content}>
          <Tooltip content="New Biz Card" relationship="label">
            <Button size="medium" className={styles.icona} icon={<CardUiRegular />}
              onClick={() => window.location.href = "#/newbizcard"} />
          </Tooltip>
        </div>
        <div className={styles.content}>
          <Tooltip content="New Event" relationship="label">
            <Button size="medium" className={styles.icona} icon={<CalendarAddRegular />}  />
          </Tooltip>
        </div>
      </div>
    </div>
  );}

