import { useState } from 'react';
import { Address } from '../types/types';
import { Input, Select, Button } from '@fluentui/react-components';
import { Building24Regular } from '@fluentui/react-icons';

export default function AddressNew() {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [newAddress, setNewAddress] = useState<Omit<Address, 'id' | 'createdAt' | 'updatedAt'>>({
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    notes: '',
    type: 'Home',
    userId: '',
    userName: '',
    tenantId: '',
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewAddress(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAddAddress = () => {
    const newId = addresses.length ? addresses[addresses.length - 1].id + 1 : 1;
    const now = new Date();
    const addressToAdd: Address = {
      ...newAddress,
      id: newId,
      createdAt: now,
      updatedAt: now,
    };
    setAddresses([...addresses, addressToAdd]);
    setNewAddress({
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      notes: '',
      type: 'Home',
      userId: '',
      userName: '',
      tenantId: '',
    });
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'Left', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '0 100px' }}>
          <h3>Current</h3>
            {addresses.map(address => (
              <div key={address.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center' }}>
                <Input appearance='underline' className='inputs'
                  type="text"
                  name="street"
                  placeholder="Street"
                  value={address.street}
                  onChange={handleInputChange}
                />
                <Input appearance='underline' className='inputs'
                  type="text"
                  name="city"
                  placeholder="City"
                  value={address.city}
                  onChange={handleInputChange}
                />
                <Input appearance='underline' className='inputs'
                  type="text"
                  name="state"
                  placeholder="State"
                  value={address.state}
                  onChange={handleInputChange}
                />
                <Input appearance='underline' className='inputs'
                  type="text"
                  name="postalCode"
                  placeholder="Postal Code"
                  value={address.postalCode}
                  onChange={handleInputChange}
                />
                <Input appearance='underline' className='inputs'
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={address.country}
                  onChange={handleInputChange}
                />
                <Input appearance='underline' className='inputs'
                  type="text"
                  name="notes"
                  placeholder="Notes"
                  value={address.notes}
                  onChange={handleInputChange}
                />
                <Select appearance='underline' className='inputs'
                  name="type"
                  value={address.type}
                  onChange={handleInputChange}
                >
                  <option value="Home">Home</option>
                  <option value="Work">Work</option>
                  <option value="Other">Other</option>
                </Select>
              </div>
            ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center' }}>
          <h3>New Address</h3>
          <Input appearance='underline' className='inputs'
            type="text"
            name="street"
            placeholder="Street"
            value={newAddress.street}
            onChange={handleInputChange}
          />
          <Input appearance='underline' className='inputs'
            type="text"
            name="city"
            placeholder="City"
            value={newAddress.city}
            onChange={handleInputChange}
          />
          <Input appearance='underline' className='inputs'
            type="text"
            name="state"
            placeholder="State"
            value={newAddress.state}
            onChange={handleInputChange}
          />
          <Input appearance='underline' className='inputs'
            type="text"
            name="postalCode"
            placeholder="Postal Code"
            value={newAddress.postalCode}
            onChange={handleInputChange}
          />
          <Input appearance='underline' className='inputs'
            type="text"
            name="country"
            placeholder="Country"
            value={newAddress.country}
            onChange={handleInputChange}
          />
          <Input appearance='underline' className='inputs'
            type="text"
            name="notes"
            placeholder="Notes"
            value={newAddress.notes}
            onChange={handleInputChange}
          />
          <Select appearance='underline' className='inputs'
            name="type"
            value={newAddress.type}
            onChange={handleInputChange}
          >
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Other">Other</option>
          </Select>
          <Button onClick={handleAddAddress}><Building24Regular /> Add Address</Button>
        </div>
      </div>
    </div>
  );
}