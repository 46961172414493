import { Input } from '@fluentui/react-components';
import React from 'react';
// import { BookGlobe24Regular } from '@fluentui/react-icons';

export default function ContactNew() {
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <label>Prefix</label>
          <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <label>First Name</label>
          <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <label>Middle Name</label>
          <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <label>Last Name</label>
          <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
          <label>Suffix</label>
          <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>Phonetic First Name</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>Phonetic Middle Name</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>Phonetic Last Name</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>Nickname</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>File As</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>Birthday</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: '10px' }}>
            <label>Anniversary</label>
            <Input appearance='underline' defaultValue={''} onChange={(e) => console.log(e.target.value)} />
          </div>
        </div>
      </div>
    </div>
  );
}