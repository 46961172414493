import { useState, useEffect } from 'react';
import { Company } from '../types/types';
import { Button, Input } from '@fluentui/react-components';

export default function CompanyNew() {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newCompany, setNewCompany] = useState<Company>({
    id: 0,
    name: '',
    industry: '',
    address: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    userId: "0",
    userName: '',
    tenantId: "0",
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        // Replace with your API endpoint or static data
        const response = await fetch('/api/companies');
        const data = await response.json();
        setCompanies(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch companies');
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);
  const handleAddCompany = () => {
    const newId = companies.length ? companies[companies.length - 1].id + 1 : 1;
    const now = new Date();
    const companyToAdd: Company = {
      ...newCompany,
      id: newId,
      createdAt: now,
      updatedAt: now,
    };
    setCompanies([...companies, companyToAdd]);
    setNewCompany({
      id: 0,
      name: '',
      industry: '',
      address: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      userId: "0",
      userName: '',
      tenantId: "0",
    });
  };
  if (loading) return <div>Loading...</div>;
  if (error) console.log(error);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', textAlign: 'center' }}>
      <h3>Company Details</h3>
        {companies.map(company => (
          <div key={company.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
            <div>Name: {company.name}</div>
            <div>Industry: {company.industry}</div>
            <div>Address: {company.address}</div>
           </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'center' }}>
        <h3>Add Company</h3>
        <Input appearance='underline' className='inputs'
          type="text"
          name="name"
          placeholder="Name"
          value={newCompany.name}
          onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
        />
        <Input appearance='underline' className='inputs'
          type="text"
          name="industry"
          placeholder="Industry"
          value={newCompany.industry}
          onChange={(e) => setNewCompany({ ...newCompany, industry: e.target.value })}
        />
        <Input appearance='underline' className='inputs'
          type="text"
          name="address"
          placeholder="Address"
          value={newCompany.address}
          onChange={(e) => setNewCompany({ ...newCompany, address: e.target.value })}
        />
        <Button onClick={handleAddCompany}>Add Company</Button>
      </div>
    </div>
  );
}