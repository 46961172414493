import axios from 'axios';
import config from "../../lib/config";

export const newBizCard = async (bizCardObj: any, token: string) => {
  try {
    // const res = await fetch('https://cutuluscontacts.azurewebsites.net/bizcards', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${token}`
    //   },
    //   body: JSON.stringify(bizCardObj)
    // });
    // const response = await res.json();
    // console.log('Server response:', response);
    // return response;

    const response = await axios.post(config.apiEndpoint+'/api/createBizCard', bizCardObj, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
      method: 'POST',
    });
    console.log('Server response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading biz card data:', error);
    throw error;
  }
};

export const uploadFile = async (file: File) =>{
  console.log(file);
  try {
    console.log('Set FormData');
    const formData = new FormData();
    formData.append('fileSize', file.size.toString());
    formData.append('fileType', file.type);
    formData.append('file', file);
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
    const fileUpload = await axios.post(config.apiEndpoint+'/api/uploadFileToBlob', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    console.log(fileUpload.data);
    return fileUpload.data.url;
  } catch(err){
    console.log(err);
    throw err;
  }
}