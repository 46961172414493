import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Input, Select } from '@fluentui/react-components';
import { Phone24Regular } from '@fluentui/react-icons';

interface PhoneNumber {
  id: string;
  phone: string;
  isActive: boolean;
  type: string;
}

interface PhoneNewProps {
  contactId: string;
}

export default function PhoneNew({ contactId }: PhoneNewProps) {
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newPhone, setNewPhone] = useState<string>('');
  const [newType, setNewType] = useState<string>('home');
  const [countryCode, setCountryCode] = useState<string>('+1'); // Default to US country code
  useEffect(() => {
    const fetchPhoneNumbers = async () => {
      try {
        const response = await axios.get(`/api/contacts/${contactId}/phones`);
        setPhoneNumbers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch phone numbers');
        setLoading(false);
      }
    };
    fetchPhoneNumbers();
  }, [contactId]);
  const handleTagChange = (id: string, newType: string) => {
    setPhoneNumbers(prevNumbers =>
      prevNumbers.map(phone =>
        phone.id === id ? { ...phone, type: newType } : phone
      )
    );
  };
  const handleAddPhoneNumber = async () => {
    try {
      const response = await axios.post(`/api/contacts/${contactId}/phones`, {
        phone: newPhone,
        type: newType,
        isActive: true,
      });
      setPhoneNumbers([...phoneNumbers, response.data]);
      setNewPhone('');
      setNewType('home');
    } catch (err) { setError('Failed to add phone number'); }
  };
  if (loading) return <div>Loading...</div>;
  if (error) console.error(error);
  return (
    <div>
      <h2>Phone Numbers</h2>
      <ul>
        {phoneNumbers.map(phone => (
          <li key={phone.id}>
            <div>Phone: {phone.phone}</div>
            <div>Active: {phone.isActive ? 'Yes' : 'No'}</div>
            <div>
              Type: 
              <select
                value={phone.type}
                onChange={(e) => handleTagChange(phone.id, e.target.value)}
              >
                <option value="home">Home</option>
                <option value="work">Work</option>
                <option value="mobile">Mobile</option>
                <option value="other">Other</option>
              </select>
            </div>
          </li>
        ))}
      </ul>
      <h3>Add New Phone Number</h3>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
        <Select
          value={countryCode} appearance='underline'
          onChange={(e) => setCountryCode(e.target.value)}
          style={{ marginRight: '10px' }}
        >
          <option value="+1">+1 (US)</option>
          <option value="+44">+44 (UK)</option>
          <option value="+91">+91 (India)</option>
          <option value="+61">+61 (Australia)</option>
          {/* Add more country codes as needed */}
        </Select>
        <Input appearance='underline' defaultValue={newPhone} onChange={(e) => setNewPhone(e.target.value)} style={{padding: '0 10px'}} />
        <Select appearance='underline' value={newType} style={{ width: '120px', padding: '0 10px' }}
          onChange={(e) => setNewType(e.target.value)}
        >
          <option value="home">Home</option>
          <option value="work">Work</option>
          <option value="mobile">Mobile</option>
          <option value="other">Other</option>
        </Select>
        <Button onClick={handleAddPhoneNumber}><Phone24Regular/> Add Phone Number</Button>
      </div>
    </div>
  );
}